import React from "react";
import * as S from "./styles";

const Home = () => {
  return (
    <>
      <S.Container>
        <object type="image/svg+xml" data="/img/home.svg" className="logo" />
      </S.Container>
    </>
  );
};

export default Home;
