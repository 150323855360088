import styled from "styled-components";

export const Container = styled.div`
  max-width: 50%;
  margin: auto;
  background-image: url("img/logo_light.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
