import styled from "styled-components";
import {
  $background_light_hover,
  background_dark,
  background_light,
} from "../../config/constants";

export const Container = styled.div`
  max-width: 50%;
  margin: auto;
  display: flex;
`;

export const Nav = styled.div`
  height: 65px;
  background-color: ${background_dark};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const NavList = styled.ul`
  display: inline;
  margin: auto;
`;

export const NavLink = styled.li`
  display: inline;
  margin-left: 10px;
  color: ${background_light};
  transition: color 0.2s;
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    color: ${$background_light_hover};
  }
`;

export const Logo = styled.object`
  height: 55px;
  margin-top: 5px;
`;
