import React from "react";
import * as S from "./styles";
const Navigation = () => {
  return (
    <S.Nav>
      <S.Container>
        <S.Logo type="image/svg+xml" data="/img/logo.svg" className="logo">
          Logo
        </S.Logo>
        <S.NavList>
          <S.NavLink>lorem</S.NavLink>
          <S.NavLink>Ipsum</S.NavLink>
          <S.NavLink>dolor</S.NavLink>
          <S.NavLink>sit</S.NavLink>
        </S.NavList>
      </S.Container>
    </S.Nav>
  );
};

export default Navigation;
