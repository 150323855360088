import React from "react";

import NavigationContainer from "../../container/Navigation";
import HomeContainer from "../../container/Home";

const HomePage = () => {
  return (
    <>
      <NavigationContainer />
      <HomeContainer />
    </>
  );
};

export default HomePage;
